import i18n from "i18next";
import translationEN from '../../src/assets/translations/en.json';
import translationES from '../../src/assets/translations/es.json';
import {initReactI18next} from "react-i18next";

// The translations resources
const resources = {
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    }

};


const language = window.navigator.language.slice(0, 2);

i18n.use(initReactI18next)
    .init({
        resources,
        lng: language,
        fallbackLng: 'en',
        keySeparator: false, // we do not use keys in form messages.welcome
        react: {
            useSuspense: true
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;