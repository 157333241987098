import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import {
    formatDate,
    getCompanyCurrency, getData, getRoundNumber, initialMomentOfDay, mergeArrays, nowMomentDate,
    nullController, saveData, setCurrencyValue,
} from "../../../../utils/helpersFunctions";
import {
    CUSTOM_ID, LAST_MONTH_ID, LAST_SIX_MONTHS_ID, LAST_THREE_DAYS_ID, LAST_THREE_MONTHS_ID, LAST_WEEK_ID, LAST_YEAR_ID,
    NOTIFICATION_DURATION, TODAY_ID, YESTERDAY_ID,
} from "../../../../variables/coreConstants";
import tableViewStyle from "../../../../assets/jss/custom-components/tableViewStyle";
import {validateSession} from "../../../../services/api";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {ROWS_PER_PAGE} from "../../../../variables/apiConstants";
import {withRouter} from "react-router-dom";
import {getTransfersByCompany, getTransferTypes} from "../../../../services/transferService";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CustomDateFilter from "../../../Analytics/Commerce/CustomDateFilter";
import Muted from "../../../../components/Typography/Muted";
import ExportTransfersCsvForm from "./ExportTransfersCsvForm";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../../../components/WijmoTableGrid/TabledGridWijmo";

const OBJECT_NAMES = require("../../../../variables/objectConstants");


class Transfers extends React.Component {

    constructor(props){
        super(props);
        this.state  = {
            currency: props.walletAccount ? props.walletAccount.currency.symbol : getCompanyCurrency(),
            isLoading: false,
            isLoadingMore: false,
            data: [],
            filteredData: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            lastPage: false,
            totalElements: 0,
            totalPages: 0,
            count: 0,
            type: { value: 0, label: props.t('TYPE_TEXT') },
            typeOptions: [],
            dateInit: initialMomentOfDay(),
            dateEnd: nowMomentDate(),
            openDate: false,
            openExport: false,
            date: { value: LAST_MONTH_ID, label: props.t('LAST_MONTH_TEXT') },
            dateOptions: [
                { value: LAST_THREE_DAYS_ID, label: props.t('LAST_THREE_DAYS_TEXT') },
                { value: TODAY_ID, label: props.t('TODAY_TEXT') },
                { value: YESTERDAY_ID, label: props.t('YESTERDAY_TEXT') },
                { value: LAST_WEEK_ID, label: props.t('LAST_WEEK_TEXT') },
                { value: LAST_MONTH_ID, label: props.t('LAST_MONTH_TEXT') },
                { value: LAST_THREE_MONTHS_ID, label: props.t('LAST_THREE_MONTHS_TEXT') },
                { value: LAST_SIX_MONTHS_ID, label: props.t('LAST_SIX_MONTHS_TEXT') },
                { value: LAST_YEAR_ID, label: props.t('LAST_YEAR_TEXT') },
                { value: CUSTOM_ID, label: props.t('CUSTOM_TEXT') },
            ],
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.prepareInitialData();
    }

    prepareInitialData = () => {
        let currentFilters = getData(OBJECT_NAMES.CURRENT_CASH_TAB);
        let dataParameters = null;

        let type = this.state.type;
        let initDate = formatDate(this.getDateTimeFromFilter(LAST_MONTH_ID));
        let endDate = formatDate(new Date().setHours(23,59, 0));

        if (currentFilters !== null && currentFilters.tab === 0) {
            type = currentFilters.transferType;

            if (currentFilters.transferDate.value !== CUSTOM_ID) {
                initDate = formatDate(this.getDateTimeFromFilter(currentFilters.transferDate.value));
                endDate = formatDate(new Date().setHours(23,59, 0));
            } else {
                initDate = formatDate(currentFilters.transferDateInit.valueOf());
                endDate = formatDate(currentFilters.transferDateEnd.valueOf());
            }

            dataParameters = {
                "page": this.state.page,
                "pageSize": this.state.rowsPerPage,
                "type": type.value,
                "initDate": initDate,
                "endDate": endDate,
                "walletAccountId": this.props.walletAccount.id
            };

            this.setState({
                type: currentFilters.transferType,
                date: currentFilters.transferDate,
                dateInit: currentFilters.transferDateInit,
                dateEnd: currentFilters.transferDateEnd
            }, () => {
                this.makeInitialRequest(dataParameters);
            });
        } else {
            dataParameters = {
                "page": this.state.page,
                "pageSize": this.state.rowsPerPage,
                "type": this.state.type.value,
                "initDate": initDate,
                "endDate": endDate,
                "walletAccountId": this.props.walletAccount.id
            };
            this.makeInitialRequest(dataParameters);
        }
    };

    makeInitialRequest = (parameters) => {
        this.setState({ isLoading: true }, () => {
            this.requestTypes();
            this.request(parameters);
        });
    };

    handleOpenExport = () => {
        this.setState({ openExport: true });
    };
    handleCloseExport = () => {
        this.setState({ openExport: false });
    };


    changeDateSelect = date => {
        this.setState({ date: date, isLoading: true }, () => {
            if (date.value !== CUSTOM_ID) {
                let initDate = this.getDateTimeFromFilter(date.value);
                let endDate = new Date().setHours(23,59, 0);

                let dataParameters = {
                    "page": this.state.page,
                    "pageSize": this.state.rowsPerPage,
                    "type": this.state.type.value,
                    "initDate": formatDate(initDate),
                    "endDate": formatDate(endDate),
                    "walletAccountId": this.props.walletAccount.id
                };

                this.request(dataParameters);
            } else {
                this.setState({ date: date, isLoading: false, openDate: true });
            }
        });
    };


    getDateTimeFromFilter = (dateFilterValue) => {
        let date = new Date();
        try {
            switch (dateFilterValue) {
                case TODAY_ID:
                    break;
                case YESTERDAY_ID: date.setDate(date.getDate() - 1);
                    break;
                case LAST_THREE_DAYS_ID: date.setDate(date.getDate() - 3);
                    break;
                case LAST_WEEK_ID: date.setDate(date.getDate() - 7);
                    break;
                case LAST_MONTH_ID: date.setDate(date.getDate() - 30);
                    break;
                case LAST_THREE_MONTHS_ID: date.setDate(date.getDate() - 90);
                    break;
                case LAST_SIX_MONTHS_ID: date.setDate(date.getDate() - 180);
                    break;
                case LAST_YEAR_ID: date.setDate(date.getDate() - 365);
                    break;
                default: date = new Date();
            }

            date.setHours(0,0, 0);
        } catch (e) {}

        return date !== null ? date : null;
    };


    handleCloseForm = (requireUpdate, states) => {
        this.setState({ openDate: false });
        if(requireUpdate && states !== null) {
            try {
                let initDate;
                let endDate;

                try {
                    initDate = states.startTime.valueOf();
                    endDate = states.endTime.valueOf();
                } catch (e) {
                    initDate = this.state.dateInit.valueOf();
                    endDate = this.state.dateEnd.valueOf();
                }

                this.setState({ isLoading: true, page: 0, dateInit: states.startTime, dateEnd: states.endTime });

                let dataParameters = {
                    "page": 0,
                    "pageSize": this.state.rowsPerPage,
                    "type": this.state.type.value,
                    "initDate": formatDate(new Date(initDate)),
                    "endDate":  formatDate(new Date(endDate).setHours(23,59, 0)),
                    "walletAccountId": this.props.walletAccount.id
                };

                this.request(dataParameters);
            } catch (e) {
                this.setState({ isLoading: false, isLoadingMore: false });
            }
        }
    };


    closeForm(state, value, type) {
        this.setState({[state]: value});
        if(type) {
            this.setState({ isLoading: true, page: 0 }, () => {
                let initDate;
                let endDate;

                if (this.state.date.value !== CUSTOM_ID) {
                    initDate = this.getDateTimeFromFilter(this.state.date.value);
                    endDate = new Date().setHours(23,59, 0);
                } else {
                    initDate = new Date(this.state.dateInit.valueOf());
                    endDate = new Date(this.state.dateEnd.valueOf()).setHours(23,59, 0);
                }

                let dataParameters = {
                    "page": 0,
                    "pageSize": this.state.rowsPerPage,
                    "type": this.state.type.value,
                    "initDate": formatDate(initDate),
                    "endDate": formatDate(endDate),
                    "walletAccountId": this.props.walletAccount.id
                };
                this.request(dataParameters);
                this.requestTypes();
            });
        }
    }




    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh !== null) {
                    this.setState({ isLoading: true, page: 0 }, () => {
                        let initDate;
                        let endDate;

                        if (this.state.date.value !== CUSTOM_ID) {
                            initDate = this.getDateTimeFromFilter(this.state.date.value);
                            endDate = new Date().setHours(23,59, 0);
                        } else {
                            initDate = new Date(this.state.dateInit.valueOf());
                            endDate = new Date(this.state.dateEnd.valueOf()).setHours(23,59, 0);
                        }

                        let dataParameters = {
                            "page": 0,
                            "pageSize": this.state.rowsPerPage,
                            "type": this.state.type.value,
                            "initDate": formatDate(initDate),
                            "endDate": formatDate(endDate),
                            "walletAccountId": this.props.walletAccount.id
                        };
                        this.request(dataParameters);
                    });
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };
    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };



    changeTypeSelect = type => {
        this.setState({ type });
        this.setState({ isLoading: true, page: 0, filteredData: [] }, () => {
            let initDate;
            let endDate;

            if (this.state.date.value !== CUSTOM_ID) {
                initDate = this.getDateTimeFromFilter(this.state.date.value);
                endDate = new Date().setHours(23,59, 0);
            } else {
                initDate = new Date(this.state.dateInit.valueOf());
                endDate = new Date(this.state.dateEnd.valueOf()).setHours(23,59, 0);
            }

            let dataParameters = {
                "page": 0,
                "pageSize": this.state.rowsPerPage,
                "type": type.value,
                "initDate": formatDate(initDate),
                "endDate": formatDate(endDate),
                "walletAccountId": this.props.walletAccount.id
            };
            this.request(dataParameters);
        });
    };



    requestTypes() {
        getTransferTypes()
            .then((response) => {
                let values = response.data.map(item => {
                    return { value: item.id, label: item.name }
                });

                this.setState({
                    typeOptions: values
                });

            })
            .catch((error) => {
                if (error.response) {
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }
            });
    }


    request(parameters) {
        getTransfersByCompany(parameters)
            .then((response) => {
                let newData = response.data !== null ? response.data.content.map(r => {
                    r.gross = nullController(r, 'currencySymbol') + setCurrencyValue(getRoundNumber(nullController(r, "gross"))).replace(nullController(r, 'currencySymbol') + "-", "- " + nullController(r, 'currencySymbol'));
                    r.net = nullController(r, 'currencySymbol') + setCurrencyValue(getRoundNumber(nullController(r, "net"))).replace(nullController(r, 'currencySymbol') + "-", "- " + nullController(r, 'currencySymbol'));
                    r.fee = nullController(r, 'currencySymbol') + setCurrencyValue(getRoundNumber(nullController(r, "fee"))).replace(nullController(r, 'currencySymbol') + "-", "- " + nullController(r, 'currencySymbol'));
                    r.balance = nullController(r, 'currencySymbol') + setCurrencyValue(getRoundNumber(nullController(r, 'balance')));
                    return r;
                }) : [];

                let paginatedData = this.state.page === 0 ? newData : mergeArrays(
                    this.state.filteredData,
                    newData,
                    "uuid",
                    null
                );


                this.setState({
                    isLoading: false,
                    isLoadingMore: false,
                    data: paginatedData,
                    filteredData: paginatedData,
                    totalElements: response.data !== null ? response.data.totalElements : 0,
                    lastPage: response.data !== null ? response.data.last : false,
                    totalPages: response.data !== null ? response.data.totalPages : 0,
                });

            })
            .catch((error) => {
                this.setState({ isLoading: false, isLoadingMore: false });
                if (error.response) {
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }
            });
    }



    handleCreateTransfer = () => {
        this.memorizeLastCashView();
        this.props.history.replace("/admin/wallet-account/"+ this.props.walletAccount.id +"/transfer-form/new");
    };

    handleBlockDepositDetails = (walletMovement) => {
        if (walletMovement.isClickable) {
            saveData("blockDeposit", walletMovement);
            this.props.history.push("/admin/wallet-account/" + this.props.walletAccount.id +"/deposit/" + walletMovement.movementId);
        }
    };

    memorizeLastCashView = () => {
        try {
            let obj = {
                tab: 0,
                page: 0,
                pageSize: 10,
                lastPage: 0,
                totalElements: 0,
                count: 0,
                transferType: this.state.type,
                transferDate: this.state.date,
                transferDateInit: this.state.dateInit,
                transferDateEnd: this.state.dateEnd
            };

            saveData(OBJECT_NAMES.CURRENT_CASH_TAB, obj);
        } catch (e) {}
    };


    handleLoadMore = () => {
        const { isLoadingMore, page, lastPage } = this.state;

        if (!isLoadingMore && !lastPage) {
            this.setState({ page: page + 1, isLoadingMore: true }, () => {
                let initDate;
                let endDate;

                if (this.state.date.value !== CUSTOM_ID) {
                    initDate = this.getDateTimeFromFilter(this.state.date.value);
                    endDate = new Date().setHours(23,59, 0);
                } else {
                    initDate = new Date(this.state.dateInit.valueOf());
                    endDate = new Date(this.state.dateEnd.valueOf()).setHours(23,59, 0);
                }

                let dataParameters = {
                    "page": this.state.page,
                    "pageSize": this.state.rowsPerPage,
                    "type": this.state.type.value,
                    "initDate": formatDate(initDate),
                    "endDate": formatDate(endDate),
                    "walletAccountId": this.props.walletAccount.id
                };

                this.request(dataParameters);
            });
        }
    };



    render(){
        const { classes, t } = this.props;
        const { totalPages, lastPage, page, rowsPerPage, totalElements, filteredData, isLoadingMore, isLoading, date, dateOptions, walletAccount } = this.state;

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoadingMore || isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12}>
                    <TableGridWijmo
                        tableName={'transfers'}
                        title={t('ACTIVITY_TXT')}
                        exportFileName={t('MOVEMENTS_TXT')}
                        handleLoadMore={this.handleLoadMore}
                        isLoading={isLoading}
                        data={filteredData}
                        actions={[
                            { label: t('TRANSFER_MONEY_TXT'), function: this.handleCreateTransfer, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[
                            { value: date, data: dateOptions, function: this.changeDateSelect, isLoading: isLoading, disabled: isLoading, type: "select"}
                        ]}
                        headers={[
                            { label: t('CREATED_DATE_TXT'), name: "date", onSelect: this.handleBlockDepositDetails, isReadOnly: true, width: "*", isCustomCell: true, clickable: true },
                            { label: t('TH_DESCRIPTION'), name: "description", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_TYPE'), name: "type", isReadOnly: true, width: 100, isCustomCell: false },
                            { label: t('PAYMENT_TEXT'), name: "status", isReadOnly: true, width: 120, cssClass: "", isCustomCell: true },
                            { label: t('TH_GROSS'), name: "gross", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_FEE'), name: "fee", isReadOnly: true, width: 80, isCustomCell: false },
                            { label: t('TH_NET'), name: "net", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_BALANCE'), name: "balance", isReadOnly: true, width: "*", isCustomCell: false },
                        ]}
                    />
                </GridItem>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={this.state.openDate}
                    onClose={() => this.handleCloseForm(false, null)}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t('CUSTOM_DATE_FILTER')}
                    </DialogTitle>
                    <DialogContent>
                        <CustomDateFilter closeForm={this.handleCloseForm}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={this.state.openExport}
                    onClose={() => this.handleCloseExport()}
                    aria-labelledby="form-dialog-export"
                >
                    <DialogTitle id="form-dialog-title">
                        {t('EXPORT_TITLE')}
                        <Muted>
                            {t('EXPORT_DESCRIPTION')}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        <ExportTransfersCsvForm closeForm={this.handleCloseExport} walletAccountId={walletAccount ? walletAccount.id : ''}/>
                    </DialogContent>
                </Dialog>
            </GridContainer>
        );
    }
}

Transfers.propTypes = {
    classes: PropTypes.object,
    showNotification: PropTypes.func,
    walletAccount: PropTypes.object,
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(Transfers)));
