import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {withRouter} from "react-router-dom";
//import Button from "../../components/CustomButtons/Button";
import {IMG_MAINTENANCE} from "../../variables/resourcesConstants";
import {ERROR_REQUEST_MESSAGE} from "../../variables/coreConstants";


const useStyles = {
    paper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position:'fixed',
    },
    messageComponent: {
        width: "40%",
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        position:'fixed',

    },
    image:{
        width: '250',
        height: '250px',
    },
    title:{
        marginTop: '15px'
    },

};


class ErrorPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            message: ERROR_REQUEST_MESSAGE,
            imgMessage: IMG_MAINTENANCE,
        };
    }

    componentDidMount() {

    }

    /*goBackRoute () {
        this.props.history.push("/admin");
    }*/


    render() {
        const { classes } = this.props;

        /*const BackButton= () => (
            <Button
                variant="contained"
                color="primary"
                onClick={() => this.goBackRoute()}
            >
                Go Back
            </Button>
        );*/

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} >
                    <div className={classes.paper}>
                        <div className={classes.messageComponent}>
                            <img className={classes.image} src={this.state.imgMessage}  alt={"Message Icon"}/>
                            <h3>{this.state.message}</h3>
                        </div>
                        </div>
                </GridItem>
            </GridContainer>
        );
    }
}

export default withRouter(withStyles(useStyles)(ErrorPage));
