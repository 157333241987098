import React from "react";
import ErrorPage from "../../views/ErrorPage/ErrorPage";

class ErrorController extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrors(error, errorInfo);
        //console.log(error + ": " + errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage history={this.props.history}/>;
        } else {
            return this.props.children;
        }
    }
}

export default ErrorController;